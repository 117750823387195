
import { Component, Vue, Model, Prop, Emit } from 'vue-property-decorator'
import { TreeMode } from '@/components/ColumnTree/TreeMode.enum'

/**
 * TreeMode-Eintrag.
 */
interface TreeModeDetail {
  /**
   * Baum-Modus.
   */
  mode: TreeMode

  /**
   * Zu verwendendes Icon.
   */
  icon: string

  /**
   * Zu verwendender Titel.
   */
  title: string
}

/**
 * Auswahl-Button für den Modus des ColumnTree.
 */
@Component({})
export default class ColumnTreeMode extends Vue {
  /**
   * Aktiver [[TreeMode]].
   */
  @Model('change', { type: String })
  public mode!: TreeMode

  /**
   * Gibt an, ob [[TreeMode.Select]] im Dropdown verfügbar sein soll.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public selectAvailable!: boolean

  /**
   * Gibt an, ob [[TreeMode.Move]] im Dropdown verfügbar sein soll.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public moveAvailable!: boolean

  /**
   * Aktiver Eintrag im Dropdown.
   *
   * @returns Details zum aktiven Eintrag.
   */
  public get activeItem(): TreeModeDetail {
    const item = this.dropdownItems.find(
      (item): boolean => item.mode === this.mode
    )

    if (!item) {
      return {
        mode: TreeMode.None,
        icon: 'mdi-help',
        title: '?'
      }
    }

    return item
  }

  /**
   * Alle Einträge des Dropdowns.
   *
   * @returns Aktive Einträge.
   */
  public get dropdownItems(): TreeModeDetail[] {
    const items: TreeModeDetail[] = [
      {
        mode: TreeMode.None,
        icon: 'mdi-cancel',
        title: 'Nichts'
      }
    ]

    if (this.selectAvailable) {
      items.push({
        mode: TreeMode.Select,
        icon: 'mdi-checkbox-multiple-marked',
        title: 'Auswahl'
      })
    }

    if (this.moveAvailable) {
      items.push({
        mode: TreeMode.Move,
        icon: 'mdi-drag-variant',
        title: 'Verschieben'
      })
    }

    return items
  }

  /**
   * Feuert das "change"-Event mit dem ausgewáhlten [[TreeMode]].
   *
   * @param mode - Ausgewählter [[TreeMode]].
   * @returns Ausgewählter [[TreeMode]].
   */
  @Emit('change')
  public emitChange(mode: TreeMode): TreeMode {
    return mode
  }
}
