
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator'
import { BasicTreeItem } from '@/interfaces/BasicTreeItem.interface'
import ColumnTree from '@/components/ColumnTree/ColumnTree.vue'

/**
 * Ein Baum, der diverse Elementen wie Orte, Eigenschaften, etc. zur Auswahl
 * bereitstellt.
 */
@Component({
  components: {
    ColumnTree
  }
})
export default class ItemPicker extends Vue {
  /**
   * Öffnet oder schließt den Dialog
   */
  public pickerOpen = false

  /**
   * Array ausgewählter Elemente
   * Wird mit der Property selectedItems aus der columnTree-Komponente
   * synchronisiert
   */
  @PropSync('selectedItems', {
    type: Array,
    required: false,
    default: (): BasicTreeItem[] => []
  })
  public syncedSelectedItems!: BasicTreeItem[]

  /**
   * Icon, welches für den Auswahl-Button verwendet wird
   */
  @Prop({ type: String, required: false })
  public buttonIcon!: string

  /**
   * Ein Text, der für den Auswahl-Button verwendet wird
   * Wird ggf. durch eine Übersetzung ersetzt, falls der Inhalt einem
   * Sprachstring entspricht
   */
  @Prop({ type: String, required: false })
  public buttonLabel!: string

  /**
   * Ein Text, der für den Bestätigen-Button innerhalb des Dialogs verwendet
   * wird
   */
  @Prop({ type: String, required: false })
  public buttonDone!: string

  /**
   * Setzt den Button auf inaktiv
   */
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  /**
   * Gibt den Text des Auswahl-Buttons zurück. Ist die Property [[buttonLabel]]
   * ein Sprachstring, wird dieser durch die passende Übersetzung ersetzt.
   *
   * @returns Button-Label
   */
  public getButtonLabel(): string {
    let translation = this.buttonLabel || 'itemPicker.buttonLabel.default'

    if (this.$te(translation)) {
      translation = this.$tc(translation, this.syncedSelectedItems.length, {
        count: this.syncedSelectedItems.length
      }).toString()
    }

    return translation
  }
}
