/**
 * Bearbeitungsmodus des Baums.
 */
export enum TreeMode {
  /**
   * Nichts.
   */
  None = 'none',

  /**
   * Auswahlmodus.
   */
  Select = 'select',

  /**
   * Verschiebungsmodus.
   */
  Move = 'move'
}
