
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { TreeMode } from '@/components/ColumnTree/TreeMode.enum'
import { TreeItem } from '@/interfaces/TreeItem.interface'

/**
 * Action-Bereich eines Listeneintrags im `ColumnTree`.
 */
@Component({})
export default class ColumnTreeItemAction extends Vue {
  /**
   * Aktiviert experimentelle Features wie das Verschieben von Einträgen.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public experimentalFeatures!: boolean

  /**
   * Aktiver Bearbeitungsmodus.
   */
  @Prop({ type: String, required: true })
  public mode!: TreeMode

  /**
   * Gibt an, ob nur ein einzelnes Element ausgewählt werden kann (Radiobox)
   * oder nicht (Checkbox).
   */
  @Prop({ type: Boolean, required: true })
  public singleSelection!: boolean

  /**
   * Gibt an, ob das Element zurzeit angewählt ist.
   */
  @Prop({ type: Boolean, required: true })
  public isSelected!: boolean

  /**
   * Gibt an, ob die maximale Anzahl an Elementen angewählt ist.
   */
  @Prop({ type: Boolean, required: true })
  public selectionCapped!: boolean

  /**
   * Gibt an, ob der Layer, auf dem sich das Element befindet, geöffnet ist.
   */
  @Prop({ type: Boolean, required: true })
  public layerIsOpen!: boolean

  /**
   * Gibt an, ob der Listeneintrag innerhalb eines Suchergebnisses dargestellt
   * wird.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public isSearch!: boolean

  /**
   * Das anzuzeigende Element.
   */
  @Prop({ type: Object, required: true })
  public item!: TreeItem

  /**
   * Toggle-Event. Wird mit dem [[TreeItem]] gefeuert, wenn die Checkbox an-
   * oder abgewählt wird.
   *
   * @param item - Das zu feuernde [[TreeItem]].
   * @returns Gefeuertes [[TreeItem]].
   */
  @Emit('toggle')
  public emitToggle(item: TreeItem): TreeItem {
    return item
  }
}
