
import {
  Component,
  Vue,
  Prop,
  Emit,
  Ref,
  PropSync,
  Watch
} from 'vue-property-decorator'
import { Options } from 'sortablejs'
import Draggable from 'vuedraggable'
import { CustomPaginated } from '@/interfaces/CustomPaginated.interface'
import { VBreadcrumbsItem } from '@/interfaces/VBreadcrumbsItem.interface'
import { TreeItem } from '@/interfaces/TreeItem.interface'
import { BasicTreeItem } from '@/interfaces/BasicTreeItem.interface'
import { ServerTreeItem } from '@/interfaces/ServerTreeItem.interface'
import { ColumnTreeMoveEvent } from '@/interfaces/ColumnTreeMoveEvent.interface'
import { ColumnTreeAddEvent } from '@/interfaces/ColumnTreeAddEvent.interface'
import ColumnTreeMode from '@/components/ColumnTreeMode/ColumnTreeMode.vue'
import ColumnTreeItemAction from '@/components/ColumnTreeItemAction/ColumnTreeItemAction.vue'
import { getService } from '@/helpers/feathers'
import { TreeMode } from './TreeMode.enum'

/**
 * Baum in einer Spaltenansicht.
 */
@Component({
  components: {
    Draggable,
    ColumnTreeMode,
    ColumnTreeItemAction
  }
})
export default class ColumnTree extends Vue {
  /**
   * Gibt an, ob ein Bearbeitungs-Button in der oberen Leiste eingeblendet
   * werden soll. Bei einem Klick wird das "edit"-Event ausgelöst.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public editButton!: boolean

  /**
   * Breite einer Spalte in Pixeln (standardmäßig 500px). Wird beim Breakpoint
   * `xs` ignoriert - hier ist die Spalte immer 100% breit.
   */
  @Prop({ type: Number, required: false, default: 500 })
  public columnWidth!: number

  /**
   * Baumstruktur/Daten.
   */
  public items: TreeItem[] = []

  /**
   * Aktiviert den Dialog-Modus, bei dem der äußere Container so positioniert
   * wird, dass er innerhalb einer <v-card> in einem <v-dialog> verwendet werden
   * kann.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public dialog!: boolean

  /**
   * Aktiviert den Bearbeitungs-Modus, bei dem Elemente umbenannt, hinzugefügt
   * oder gelöscht werden können. Erforderlich, um den optionalen "Bearbeiten"-
   * Button anzuzeigen.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public enableEditing!: boolean

  /**
   * Aktiviert den Mehrfachauswahl-Modus für diesen Baum.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public selectAvailable!: boolean

  /**
   * Aktiviert den Verschiebungs-Modus für diesen Baum.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public moveAvailable!: boolean

  /**
   * Zu verwendender Feathers-Service für AJAX-Anfragen.
   */
  @Prop({ type: String, required: true })
  public serviceName!: string

  /**
   * ID-Eigenschaft des Datensatz-Objekts.
   */
  @Prop({ type: String, required: false, default: 'id' })
  public idProperty!: string

  /**
   * Titel-Eigenschaft des Datensatz-Objekts.
   */
  @Prop({ type: String, required: false, default: 'title' })
  public titleProperty!: string

  /**
   * Optionale Eigenschaft mit dem Icon-Namen des Datensatz-Objekts.
   */
  @Prop({ type: String, required: false, default: undefined })
  public iconProperty?: string

  /**
   * Eltern-Eigenschaft (ID des übergeordneten Elements) des Datensatz-Objekts.
   */
  @Prop({ type: String, required: false, default: 'parent' })
  public parentProperty!: string

  /**
   * Maximale Anzahl an ausgewählten Elementen für [[TreeMode.Select]]. Bei
   * einem Wert von 0 oder `undefined` ist die Auswahl nicht begrenzt, bei 1
   * werden Radio-Buttons statt Checkboxen angezeigt.
   */
  @Prop({ type: Number, required: false, default: undefined })
  public maximumSelections?: number

  /**
   * Aktiviert experimentelle Features wie das Erstellen, Bearbeiten,
   * Verschieben und Löschen von Einträgen.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public experimentalFeatures!: boolean

  /**
   * `<v-breadcrumbs>` Komponente.
   */
  @Ref()
  public breadcrumbs!: Vue

  /**
   * Äußeres Container `<div>`.
   */
  @Ref()
  public outerContainer!: Element

  /**
   * Vom Benutzer gewählter aktiver Bearbeitungsmodus.
   */
  public userSelectedMode: TreeMode = TreeMode.Select

  /**
   * Gibt an, ob ein Layer geladen wird.
   */
  public isLoading = false

  /**
   * Gibt an, ob der Button zum Umschalten der Modi aktiviert ist. Der Button
   * wird nur aktiviert, wenn mehrere Modi für diesen Baum aktiviert sind.
   *
   * @returns Ob der Button angezeigt wird.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public get toggleButtonEnabled(): boolean {
    return (
      this.experimentalFeatures && this.selectAvailable && this.moveAvailable
    )
  }

  /**
   * Gibt an, ob nur eine Spalte in voller Breite angezeigt werden soll. Ergibt
   * sich aus dem aktuellen Breakpoint, bei `xs` ist die Option aktiviert.
   *
   * @returns Ob nur eine Spalte angezeigt wird.
   */
  public get singleColumn(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  /**
   * Erzeugt das Style-Element eines Spalten-Sheets unter Beachtung von
   * [[singleColumn]].
   *
   * @param columnIndex - Index der Spalte.
   * @returns Style-Objekt.
   */
  public columnStyle(columnIndex: number): object {
    return {
      left: `calc(${columnIndex} * ${
        this.singleColumn ? '100%' : this.columnWidth + 'px'
      })`,
      width: this.singleColumn ? '100%' : this.columnWidth + 'px'
    }
  }

  /**
   * Aktiver Bearbeitungsmodus. Bei nur einem erlaubten Modus wird dieser
   * unabhängig von der Benutzerauswahl zurückgegeben.
   *
   * @returns Aktiver Bearbeitungsmodus.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public get mode(): TreeMode {
    if (this.selectAvailable && !this.moveAvailable) {
      return TreeMode.Select
    }

    if (this.moveAvailable && !this.selectAvailable) {
      return TreeMode.Move
    }

    if (!this.moveAvailable && !this.selectAvailable) {
      return TreeMode.None
    }

    return this.userSelectedMode
  }

  /**
   * Setzt den vom Benutzer gewählten Bearbeitungsmodus.
   *
   * @param value - Bearbeitungsmodus.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public set mode(value: TreeMode) {
    this.userSelectedMode = value
  }

  /**
   * Gibt an, ob der Umbenennungsmodus gerade aktiv ist.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public renameMode = false

  /**
   * IDs der ausgewählten Ebenen.
   */
  public selectedLayers: (number | null)[] = [null]

  /**
   * Einstellungen für das `<draggable>` Element.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public readonly dragOptions: Options = {
    animation: 200,
    ghostClass: 'column-tree__ghost',
    swapThreshold: 0.4,
    invertSwap: true,
    setData: (dataTransfer: DataTransfer): void => {
      dataTransfer.setDragImage(document.createElement('div'), 0, 0)
    }
  }

  /**
   * Attribute für das Element (`<v-list>`), welches von `<draggable>` erstellt
   * wird.
   */
  public readonly draggableComponentData = {
    attrs: {
      class: 'py-0'
    }
  }

  /**
   * Generiert ein Array mit Daten fúr die `<v-breadcrumbs>` Komponente für den
   * aktuell geöffneten Pfad.
   *
   * @returns Mit "items"-Property von `<v-breadcrumbs>` kompatibles Array.
   */
  public get breadcrumbsItems(): VBreadcrumbsItem[] {
    return this.layers
      .map(
        (x): VBreadcrumbsItem => ({
          text: x.title,
          disabled: this.layers.indexOf(x) !== this.layers.length - 1
        })
      )
      .splice(1)
  }

  /**
   * Gibt das aktive Element zurück.
   *
   * @returns Das aktive Element.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public get activeItem(): TreeItem {
    return this.layers[this.layers.length - 1]
  }

  /**
   * Generiert ein Array aller anzuzeigenden Baumebenen.
   *
   * @returns Generiertes Array.
   */
  public get layers(): TreeItem[] {
    let root: TreeItem[] = this.items

    const layers: TreeItem[] = []

    for (const layerID of this.selectedLayers) {
      const result = root.find((item): boolean => item.id === layerID)

      if (!result) {
        // throw new Error('Tree structure incorrect or damaged.')
        this.selectedLayers.length = this.selectedLayers.indexOf(layerID) + 1
        console.warn('[[selectedLayers]] fixed automatically.')
        break
      }

      root = result.children

      layers.push(result)
    }

    return layers
  }

  /**
   * Generiert die Klassenliste für das äußere <v-card> Element.
   *
   * @returns Klassenliste.
   */
  public get cardClassList(): string[] {
    const classes = ['column-tree__card']

    if (this.dialog) {
      classes.push('column-tree__card--dialog')
    } else {
      classes.push('column-tree__card--page')
    }

    return classes
  }

  /**
   * Liste ausgewählter Elemente. Muss als Property übergeben werden und wird
   * synchronisiert.
   */
  @PropSync('selectedItems', {
    type: Array,
    required: false,
    default: (): BasicTreeItem[] => []
  })
  public syncedSelectedItems!: BasicTreeItem[]

  /**
   * IDs der Elemente aus [[syncedSelectedItems]].
   *
   * @returns Liste von IDs.
   */
  public get selectedItemIDs(): (number | null)[] {
    return this.syncedSelectedItems.map((item): number | null => item.id)
  }

  /**
   * Prüft, ob ein Eintrag ausgewählt ist.
   *
   * @param element - Zu prüfender Eintrag.
   * @returns Ergebnis der Prüfung.
   */
  public isSelected(element: TreeItem): boolean {
    return this.selectedItemIDs.indexOf(element.id) > -1
  }

  /**
   * Erstellt einen Pfad (Breadcrumbs) zu einem in [[item]] angegebenen Knoten
   * unter Berücksichtigung der [[parents]] aus der Baumstruktur.
   *
   * @param item - Knoten-Daten, dessen Breadcrumb erstellt werden soll
   * @param parents - potentielle Elternknoten
   * @returns Array mit Breadcrumb-Items
   */
  private getBreadcrumbData(
    item: ServerTreeItem,
    parents: ServerTreeItem[]
  ): VBreadcrumbsItem[] {
    const breadcrumbs: VBreadcrumbsItem[] = []

    breadcrumbs.push({
      text: item[this.titleProperty] as string,
      treeNodeID: item[this.idProperty] as number
    })

    let parentId: number | null = item[this.parentProperty] as number

    do {
      const parent = parents.find(
        (p: ServerTreeItem): boolean => p[this.idProperty] === parentId
      )

      if (parent) {
        breadcrumbs.push({
          text: parent[this.titleProperty] as string,
          treeNodeID: parent[this.idProperty] as number
        })

        parentId = parent[this.parentProperty] as number | null
      } else {
        parentId = null
      }
    } while (parentId !== null)

    return breadcrumbs.reverse()
  }

  /**
   * Springt im Baum zu einem bestimmten Knoten.
   * Die aktuell angezeigten Layer werden resettet und anhand der Breadcrumb-
   * Daten neu aufgebaut.
   *
   * @param layerID - ID des Knotens
   */
  public async openLayer(layerID: number): Promise<void> {
    const fullitem = this.searchResults.find((i): boolean => i.id === layerID)

    if (!fullitem) {
      throw new Error('Selected item not found!')
    }

    const breadcrumbItems = fullitem.breadcrumbs || []

    this.selectedLayers = [null]

    // alle Kinder der root-Ebene des Baums
    let allItems = this.items[0].children

    let idx = 0
    for (const bc of breadcrumbItems) {
      const layer = allItems.find((i): boolean => i.id === bc.treeNodeID)

      if (layer !== undefined) {
        await this.select(layer, idx)
        allItems = layer.children
        idx++
      }
    }

    // geht in der Ansicht eine Ebene hoch, wenn die letzte Ebene keine Kinder
    // mehr hat, die man anzeigen könnte.
    if (allItems.length === 0) {
      this.up()
    }

    this.searchActive = false
  }

  /**
   * Schaltet einen Eintrag zwischen selektiert und nicht selektiert um.
   *
   * @param element - Eintrag, der umgeschaltet werden soll.
   */
  public toggleSelected(element: TreeItem): void {
    if (this.maximumSelections === 1) {
      this.syncedSelectedItems.length = 0
      this.syncedSelectedItems.push({
        id: element.id,
        title: element.title
      })
    } else if (this.isSelected(element)) {
      const item = this.syncedSelectedItems.find(
        (item): boolean => item.id === element.id
      )

      if (!item) {
        throw new Error('Selected item not found!')
      }

      this.syncedSelectedItems.splice(this.syncedSelectedItems.indexOf(item), 1)
    } else {
      this.syncedSelectedItems.push({
        id: element.id,
        title: element.title
      })
    }
  }

  /**
   * Prüft, ob die angegebene Ebene aktuell geöffnet ist.
   *
   * @param layerID - ID der Ebene/des Elements.
   * @returns Das Ergebnis der Prüfung.
   */
  public layerIsOpen(layerID: number): boolean {
    return this.selectedLayers.indexOf(layerID) > -1
  }

  /**
   * Drag-and-Drop Handler-Funktion.
   *
   * @param updatedList - Aktualisierte/umsortierte Liste.
   * @param parentItem - Eltern-Element der aktualisierten Liste.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public moveHandler(updatedList: TreeItem[], parentItem: TreeItem): void {
    const index = this.layers.indexOf(parentItem)

    let root: TreeItem[] = this.items
    let baseItem: TreeItem | null = null

    for (let i = 0; i < this.selectedLayers.length; ++i) {
      const layerID = this.selectedLayers[i]

      const result = root.find((item): boolean => item.id === layerID)

      if (!result) {
        throw new Error('Tree structure incorrect or damaged.')
      }

      if (i === index) {
        baseItem = result
        break
      }

      root = result.children
    }

    if (baseItem) {
      baseItem.children = updatedList
    }

    this.emitMove(parentItem)
  }

  /**
   * Entfernt die tiefste Ebene aus den geöffneten Layern ("aufwärts") und
   * scrollt die View komplett nach rechts.
   */
  public up(): void {
    if (this.selectedLayers.length > 1) {
      this.selectedLayers.pop()
    }
    this.scrollRight()
  }

  /**
   * Handler für die Auswahl eines Elements in der Spaltenansicht.
   *
   * @param item - Ausgewähltes Element.
   * @param layerIndex - Index der Layer-ID in [[this.selectedLayers]], der das
   * ausgewählte Element enthält.
   */
  public async select(item: TreeItem, layerIndex: number): Promise<void> {
    this.selectedLayers.length = layerIndex + 1
    this.selectedLayers.push(item.id)

    if (item.childCount && !item.children.length) {
      await this.loadLayer(item)
    }

    await this.$nextTick()

    this.scrollRight()
  }

  /**
   * Scrollt die Spaltenansicht vollständig nach rechts.
   */
  public scrollRight(): void {
    this.outerContainer.scrollLeft = this.outerContainer.scrollWidth

    if (this.breadcrumbs) {
      this.breadcrumbs.$el.scrollLeft = this.breadcrumbs.$el.scrollWidth
    }
  }

  /**
   * Gibt an, ob aktuell die maximale Anzahl an Einträgen ausgewählt ist.
   *
   * @returns `true`, wenn die maximale Anzahl ausgewählt ist, sonst `false`.
   */
  public get selectionCapped(): boolean {
    if (!this.maximumSelections) {
      return false
    }

    return this.syncedSelectedItems.length >= this.maximumSelections
  }

  /**
   * Fügt ein Element zu der angegebenen Ebene hinzu.
   *
   * @param layer - Ebene, zu der das Element hinzugefügt werden soll.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public async addItem(layer: TreeItem): Promise<void> {
    const serverItem = await (
      await getService(this.serviceName)
    ).create({
      [this.titleProperty]: this.$t('columnTree.placeholder.new'),
      [this.parentProperty]: layer.id
    })

    const newItem: TreeItem = this.toTreeItem(serverItem as ServerTreeItem)

    layer.children.push(newItem)

    this.emitAdd(layer, newItem)
  }

  /**
   * Konvertiert ein [[ServerTreeItem]] in ein [[TreeItem]].
   *
   * @param serverTreeItem - [[ServerTreeItem]]
   * @returns [[TreeItem]]
   */
  public toTreeItem(serverTreeItem: ServerTreeItem): TreeItem {
    const treeItem: TreeItem = {
      children: [],
      childCount: serverTreeItem.$children,
      id: serverTreeItem[this.idProperty] as number,
      title: serverTreeItem[this.titleProperty] as string
    }

    if (this.iconProperty) {
      treeItem.icon = serverTreeItem[this.iconProperty] as string
    }

    return treeItem
  }

  /**
   * Löscht das aktive Element aus dem Baum.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  public deleteActiveItem(): void {
    if (this.layers.length < 2) {
      return
    }

    const layer = this.layers[this.layers.length - 2]
    const item = this.layers[this.layers.length - 1]

    layer.children.splice(layer.children.indexOf(item), 1)

    this.emitDelete()
  }

  /**
   * Konvertiert ein [[TreeItem]] in ein [[BasicTreeItem]].
   *
   * @param item - Quell-Objekt.
   * @returns Konvertiertes Objekt.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  private _convertToBasicTreeItem(item: TreeItem): BasicTreeItem {
    return {
      id: item.id,
      title: item.title
    }
  }

  /**
   * "Created" Event-Handler.
   */
  public created(): void {
    const root = {
      id: null,
      children: [],
      selected: false,
      title: '',
      icon: ''
    }

    this.items.push(root)
    this.loadLayer(root)
  }

  /**
   * Lädt eine Baumschicht.
   *
   * @param target - Ziel-Elternelement.
   */
  public async loadLayer(target: TreeItem): Promise<void> {
    this.isLoading = true
    const result = (await (
      await getService(this.serviceName)
    ).find({
      query: {
        $orderBy: this.titleProperty,
        $parent: target.id || undefined
      }
    })) as CustomPaginated<ServerTreeItem>

    target.children = []

    for (const item of result.data as ServerTreeItem[]) {
      target.children.push(this.toTreeItem(item))
    }

    this.isLoading = false
  }

  /**
   * Feuert das "edit"-Event mit dem gegebenen Element nach außen.
   *
   * @param item - Element, für welches das Event ausgelöst werden soll. Ohne
   * Angabe wird das aktive Element verwendet.
   * @returns Das bearbeitete Element.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Emit('edit')
  public emitEdit(item: TreeItem = this.activeItem): BasicTreeItem {
    return this._convertToBasicTreeItem(item)
  }

  /**
   * Feuert das "move"-Event mit einem [[ColumnTreeMoveEvent]].
   *
   * @param parentItem - Eltern-Element der aktualisierten Liste/Ebene.
   * @returns Das [[ColumnTreeMoveEvent]] Objekt.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Emit('move')
  public emitMove(parentItem: TreeItem): ColumnTreeMoveEvent {
    const moveEvent: ColumnTreeMoveEvent = {
      parentID: parentItem.id as number,
      order: parentItem.children.map((item): number => item.id as number)
    }

    return moveEvent
  }

  /**
   * Feuert das "delete"-Event mit dem gegebenen Element nach außen.
   *
   * @param item - Element, für welches das Event ausgelöst werden soll. Ohne
   * Angabe wird das aktive Element verwendet.
   * @returns Das gelöschte Element.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Emit('delete')
  public emitDelete(item: TreeItem = this.activeItem): BasicTreeItem {
    return this._convertToBasicTreeItem(item)
  }

  /**
   * Feuert das "add"-Event mit einem [[ColumnTreeAddEvent]].
   *
   * @param item - Hinzugefügtes Element.
   * @param parentItem - Übergeordnetes Element.
   * @returns Das [[ColumnTreeAddEvent]] Objekt.
   *
   * Experimentell! Erfordert [[experimentalFeatures]] === `true`.
   */
  @Emit('add')
  public emitAdd(item: TreeItem, parentItem: TreeItem): ColumnTreeAddEvent {
    const addEvent: ColumnTreeAddEvent = {
      parentID: parentItem.id as number,
      addedItemID: item.id as number
    }

    return addEvent
  }

  /**
   * Referenz zum DOM-Element des Suchfelds (`<v-autocomplete>`).
   */
  @Ref()
  public searchField!: Element

  /**
   * Wird bei Änderungen am Suchbegriff aufgerufen, um die Suchergebnisse anhand
   * einer Serveranfrage zu aktualisieren.
   *
   * @param newValue - Neuer Wert des Suchbegriffs ([[searchTerm]]).
   */
  @Watch('searchTerm')
  public async searchCall(newValue?: string): Promise<void> {
    const term = newValue ? newValue.trim() : ''

    if (!term) {
      this.searchResults = []
      return
    }

    this.searchIsLoading = true

    const result = (await (
      await getService(this.serviceName)
    ).find({
      query: {
        $search: term,
        $parentless: true,
        $fetchParents: true
      }
    })) as CustomPaginated<ServerTreeItem>

    this.searchResults = result.data
      .map((item): TreeItem => {
        const treeitem = this.toTreeItem(item)
        treeitem.breadcrumbs = this.getBreadcrumbData(
          item,
          result.parents || []
        )
        treeitem.breadcrumbString = treeitem.breadcrumbs
          .map((bc): string => bc.text as string)
          .join('>')

        return treeitem
      })
      .sort((a: TreeItem, b: TreeItem): number =>
        (a.breadcrumbString || '').localeCompare(b.breadcrumbString || '')
      )

    this.searchIsLoading = false
  }

  /**
   * Gibt an, ob die Suche momentan aktiv ist (Suchfeld wird angezeigt).
   */
  public searchActive = false

  /**
   * Suchstring.
   */
  public searchTerm = ''

  /**
   * Gibt an, ob die Suchergebnisse geöffnet sind.
   */
  public searchResultsOpen = false

  /**
   * Liste mit Suchergebnissen.
   */
  public searchResults: TreeItem[] = []

  /**
   * Gibt an, ob momentan Suchergebnisse geladen werden.
   */
  public searchIsLoading = false
}
